// Pinia Store
import { computed } from 'vue';
import { defineStore } from 'pinia';
import globalConstants from '@/globalConstants';

const {
  USER_JOURNEY_TALENT_MANUAL_PAGES,
  USER_JOURNEY_V3_PAGES,
} = globalConstants;

console.log('Pinia GetReadyProgram store is being created.'); // no access to Vue.prototype.$log here yet

const useGetReadyProgramStore = defineStore('getReadyProgram', () => {
  const oldJgoPages = computed(() => USER_JOURNEY_TALENT_MANUAL_PAGES);
  const oldJgoPagesPhase1 = computed(() => USER_JOURNEY_TALENT_MANUAL_PAGES.PHASE_1);
  const oldJgoPagesPhase2 = computed(() => USER_JOURNEY_TALENT_MANUAL_PAGES.PHASE_2);
  const oldJgoPagesPhase3 = computed(() => USER_JOURNEY_TALENT_MANUAL_PAGES.PHASE_3);
  const oldJgoPagesPhase4 = computed(() => USER_JOURNEY_TALENT_MANUAL_PAGES.PHASE_4);
  //
  const pages = computed(() => USER_JOURNEY_V3_PAGES);
  const pagesPhase2 = computed((): { [pageKey: string]: number } => USER_JOURNEY_V3_PAGES.PHASE_2); // GetReadyProgramImp | GetReadyProgramJgo | GetReadyProgramTly

  return {
    //
    // State
    //
    //
    //
    // Getters
    //
    oldJgoPages,
    oldJgoPagesPhase1,
    oldJgoPagesPhase2,
    oldJgoPagesPhase3,
    oldJgoPagesPhase4,
    pages,
    pagesPhase2,
    //
    // Actions
    //
    //
  };
});

export { useGetReadyProgramStore };
