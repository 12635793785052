// Pinia Store
import { computed, inject, ref } from 'vue';
import { defineStore } from 'pinia';
import type { AxiosResponse, AxiosStatic } from 'axios';

import globalConstants from '@/globalConstants';
import { type UserRole, useAccountStore } from '@/stores/account';

import type { ImpulseComment } from '@/components/logbook/ImpulseComments.vue';

console.log('Pinia Impulses store is being created.'); // no access to Vue.prototype.$log here yet

const {
  ALL_ROLES,
} = globalConstants;

interface DoingConfirmationStatus {
  PENDING: 1;
  DONE: 2;
  NOT_DONE: 3;
  EXPIRED: 4;
}

export type DoingReviewStatusId = DoingConfirmationStatus[keyof DoingConfirmationStatus];

export interface Impulse {
  actions: { allow_postpone: boolean; allow_skip: boolean };
  color: string;
  comments: ImpulseComment[];
  description: string;
  doing_review: null | {
    doing_review_status_id: DoingReviewStatusId;
    id?: number;
    user_notes: null;
  };
  hour: number;
  id: number;
  image: string;
  impulse_category_id: number;
  impulse_date: string;
  impulse_datetime: string;
  individual_empowerment_area_id: null;
  minute: number;
  rating: null | number;
  status: { id: number; name: string };
  status_id: number;
  text: string;
  transcript: { order: number; section: string; text: string }[];
  tutorial_impulse: null | TutorialImpulse;
  video: null | string;
}

export interface TutorialImpulse {
  color: string;
  description: string;
  id: number;
  image: string;
  learning_program_impulse_id: number; // needed to call /session/learning_program/advance
  text: string;
  transcript: {
    order: number;
    section: string;
    text: string;
  }[];
  tutorial_impulse_count: number;
  tutorial_impulse_order: number;
  video: null;
}

interface ActiveImpulsesResponse {
  data: {
    pending_impulses_count: number;
    role_id: number;
    user_impulse: {
      hour: number;
      id: number;
      impulse_data: string;
      impulse_datetime: string;
      minute: number;
      status_id: number;
    };
  }[];
}

export interface PersonalDevelopmentAreaArchiveData {
  color: string;
  doing_review: null | {
    doing_review_status_id: DoingReviewStatusId;
    id: number;
    user_notes: null; // TODO string?
  };
  has_comments: boolean;
  id: number;
  impulse_date: string;
  impulse_datetime: string;
  impulse_id: number;
  individual_empowerment_area_id: null | number;
  rating: null | number;
  status_id: number;
}

const useImpulsesStore = defineStore('impulses', () => {
  const $log: any = inject('$log');
  const $http: undefined | AxiosStatic = inject('$http');

  const nextImpulseDateTime = ref<null | string>(null);
  const numberOfActiveImpulsesForEachRole = ref<{
    // [ALL_ROLES.IMP_TEAM_LEADER.id]: number;
    // 3?: { pendingImpulsesCount: number, impulseDatetime: string }; // TODO key type
    // [ALL_ROLES.IMP_TEAM_MEMBER.id]: number;
    // 4?: { pendingImpulsesCount: number, impulseDatetime: string }; // TODO key type
    [key: number]: { pendingImpulsesCount: number; impulseDatetime: string };
  }>({});
  const personalDevelopmentAreaDataByRole = ref<{
    [key: string]: PersonalDevelopmentAreaArchiveData[];
  }>({});
  const learningAndDoingCountByRole = ref<{
    [key: string]: { learning_count: number; doing_count: number };
  }>({});

  const personalDevelopmentAreaTotalDoingPointsForRole = computed(() => (role: UserRole) => { // TODO types
    const data = learningAndDoingCountByRole.value[role.id];
    if (!data) {
      return 0;
    }
    return data.doing_count;
  });
  const personalDevelopmentAreaTotalLearningPointsForRole = computed(() => (role: UserRole) => { // TODO types
    const data = learningAndDoingCountByRole.value[role.id];
    if (!data) {
      return 0;
    }
    return data.learning_count;
  });

  function resetState() {
    numberOfActiveImpulsesForEachRole.value = {};
    nextImpulseDateTime.value = null;
    personalDevelopmentAreaDataByRole.value = {};
    learningAndDoingCountByRole.value = {};
  }

  async function getNumberOfActiveImpulsesForEachRole() {
    try {
      if (import.meta.env.VITE_APP_NAME !== 'imp') {
        return null;
      }
      const response: undefined | ActiveImpulsesResponse = await $http?.get('/session/cockpit/active_impulses', { signal: undefined });
      if (!response) {
        return null;
      }
      $log.debug('got number of active impulses for each role');
      $log.debug(response.data);

      // transform the response from
      // [ { "pending_impulses_count": 1, "role_id": 4 }, { "pending_impulses_count": 0, "role_id": 6 } ]
      // into
      // { 4: { pendingImpulsesCount: 1 }, 6: { pendingImpulsesCount: 0 } }
      const transformed = response.data.reduce((acc, cur) => ({
        ...acc,
        [cur.role_id]: {
          pendingImpulsesCount: cur.pending_impulses_count,
          impulseDatetime: cur.user_impulse && cur.user_impulse.impulse_datetime,
        },
      }), {});
      numberOfActiveImpulsesForEachRole.value = transformed;
      return response;
    } catch (error) {
      throw new Error(`Error fetching number of active impulses for each role: ${typeof error === 'object' && error && 'message' in error && error.message}`);
    } finally {
      // do nothing
    }
  }

  async function fetchLearningAndDoingCountForRole(payload: UserRole) {
    const roleId = payload.id;
    try {
      interface LearningDoingCountData {
        doing_count: number;
        learning_count: number;
      }

      const response: undefined | AxiosResponse<LearningDoingCountData> = await $http?.get('/session/impulses/learning-doing-count', { params: { role_id: roleId }, signal: undefined });

      if (!response) {
        return;
      }
      if (response.data && 'learning_count' in response.data && 'doing_count' in response.data) {
        learningAndDoingCountByRole.value[roleId] = response.data;
      } else {
        $log.error(`Error fetching Learning and Doing count (for role ${roleId}): no response.data or learning_count or doing_count missing:`, response.data);
      }
    } catch (error) {
      throw new Error(`Error fetching Learning and Doing count (for role ${roleId}): ${typeof error === 'object' && error && 'message' in error && error.message}`);
    } finally {
      // do nothing
    }
  }

  function setNextImpulseDateTime(payload: null | string) {
    nextImpulseDateTime.value = payload;
  }

  async function fetchPersonalDevelopmentAreaDataForRole(payload: (typeof ALL_ROLES)[keyof typeof ALL_ROLES] | { id: number }) {
    const roleId: 3 | 4 = payload.id as 3 | 4;
    try {
      interface PersonalDevelopmentAreaArchiveResponse {
        data: PersonalDevelopmentAreaArchiveData[];
      }
      const response: undefined | PersonalDevelopmentAreaArchiveResponse = await $http?.get('/session/personal_development_area/archive', { params: { role_id: roleId }, signal: undefined });
      if (!response) {
        return;
      }
      if (response.data && Array.isArray(response.data)) {
        if (!response.data.length) {
          $log.info(`Personal development area archive (for role ${roleId}): empty result`);
        }
        personalDevelopmentAreaDataByRole.value[roleId] = response.data;
      } else {
        $log.error(`Error fetching Personal Development Area details/archive (for role ${roleId}): no response.data or response.data is not an array`);
      }
    } catch (error) {
      throw new Error(`Error fetching Personal Development Area details/archive (for role ${roleId}): ${typeof error === 'object' && error && 'message' in error && error.message}`);
    } finally {
      // do nothing
    }
  }

  // This is used in the Personal Development Area view and 'fetchAccount' action in account store
  async function getPersonalDevelopmentAreaDataForEachRole() {
    if (import.meta.env.VITE_APP_NAME !== 'imp') {
      return;
    }

    const accountStore = useAccountStore();
    const hasTeamLeaderRole = accountStore.userHasImpTeamLeaderRole;
    const hasTeamMemberRole = accountStore.userHasImpTeamMemberRole;
    if (hasTeamLeaderRole) {
      await fetchPersonalDevelopmentAreaDataForRole(ALL_ROLES.IMP_TEAM_LEADER);
    }
    if (hasTeamMemberRole) {
      await fetchPersonalDevelopmentAreaDataForRole(ALL_ROLES.IMP_TEAM_MEMBER);
    }
  }

  return {
    //
    // State
    //
    numberOfActiveImpulsesForEachRole,
    nextImpulseDateTime,
    personalDevelopmentAreaDataByRole,
    learningAndDoingCountByRole,
    //
    // Getters
    //
    personalDevelopmentAreaTotalDoingPointsForRole,
    personalDevelopmentAreaTotalLearningPointsForRole,
    //
    // Actions
    //
    resetState,
    getNumberOfActiveImpulsesForEachRole,
    fetchLearningAndDoingCountForRole,
    setNextImpulseDateTime,
    fetchPersonalDevelopmentAreaDataForRole,
    getPersonalDevelopmentAreaDataForEachRole,
  };
});

export { useImpulsesStore };
