<template>
  <BModal
    v-model="isAviationSpellingModalVisible"
    teleport-disabled
    centered
    no-footer
  >
    <template #header>
      <!--{{ translations.invitations.history.actions.confirmationTitle(`${row.firstName} ${row.lastName}`) }}-->
      Spell like a Pilot!
    </template>

    <template #default>
      <div class="top-banner-image">
        <div class="text">
          Spell like a Pilot!
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="d-flex align-items-center">
            <BFormGroup class="mb-0 flex-grow-1">
              <BFormInput
                ref="inputRef"
                v-model="alphabetInput"
                type="text"
              />
            </BFormGroup>
            <AppButtonWithLed
              label="Clear"
              class="ml-gap-0_38x"
              @click="clearInput"
            />
          </div>

          <div
            ref="scrollableRef"
            class="scrollable mt-gap-1x"
          >
            <div
              v-for="(letter, index) in alphabetInputConverted"
              :key="`letter-${index}`"
              class="initial-letter-and-word-container"
            >
              <template v-if="aviationAlphabet[letter.toUpperCase()]">
                <div class="initial-letter">
                  {{ aviationAlphabet[letter.toUpperCase()].charAt(0) }}
                </div>
                {{ aviationAlphabet[letter.toUpperCase()] }}
              </template>
              <template v-else-if="aviationDigits[letter]">
                <div class="initial-letter">
                  {{ letter }}
                </div>
                {{ aviationDigits[letter] }}
              </template>
              <template v-else-if="letter === ' '">
                <br>
              </template>
              <template v-else>
                <div class="initial-letter">
                  {{ letter.toUpperCase() }}
                </div>
              </template>
            </div>
            <div ref="scrollBottomRef" />
          </div>
        </div>
        <div class="col letter-list">
          <div class="row">
            <div class="col">
              <div
                v-for="letter in aviationAlphabetLowerPartKeys"
                :key="letter"
                class="initial-letter-and-word-container"
              >
                <div class="initial-letter">
                  {{ letter }}
                </div>
                {{ aviationAlphabet[letter] }}
              </div>
            </div>
            <div class="col">
              <div
                v-for="letter in aviationAlphabetUpperPartKeys"
                :key="letter"
                class="initial-letter-and-word-container"
              >
                <div class="initial-letter">
                  {{ letter }}
                </div>
                {{ aviationAlphabet[letter] }}
              </div>
            </div>
          </div>

          <div class="row mt-gap-0_38x">
            <div class="col">
              <div
                v-for="digit in aviationDigitsLowerPartKeys"
                :key="digit"
                class="initial-letter-and-word-container"
              >
                <div class="initial-letter">
                  {{ digit }}
                </div>
                {{ aviationDigits[digit] }}
              </div>
            </div>
            <div class="col">
              <div
                v-for="digit in aviationDigitsUpperPartKeys"
                :key="digit"
                class="initial-letter-and-word-container"
              >
                <div class="initial-letter">
                  {{ digit }}
                </div>
                {{ aviationDigits[digit] }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <!--    <footer class="modal-footer w-100">-->
    <!--      <AppButtonWithLed-->
    <!--        label="Close"-->
    <!--        :blue="true"-->
    <!--        class="mr-gap-0_62x"-->
    <!--        @click="closeModal"-->
    <!--      />-->
    <!--    </footer>-->
  </BModal>
</template>

<script
  setup
  lang="ts"
>
import { computed, inject, onUnmounted, ref, watch } from 'vue';
import throttle from 'lodash.throttle';
import type { BFormInput, BFormTextarea } from 'bootstrap-vue-next';

import AppButtonWithLed from '@/components/misc/AppButtonWithLed.vue';
import type { ScrollToFunction } from '@/main';

const $scrollTo: undefined | ScrollToFunction = inject('$scrollTo');

const isAviationSpellingModalVisible = ref(false);
const cancelScroll = ref<(() => void) | null>(null);
const alphabetInput = ref('');
const aviationAlphabet: Record<string, string> = {
  A: 'Alpha',
  B: 'Bravo',
  C: 'Charlie',
  D: 'Delta',
  E: 'Echo',
  F: 'Foxtrot',
  G: 'Golf',
  H: 'Hotel',
  I: 'India',
  J: 'Juliet',
  K: 'Kilo',
  L: 'Lima',
  M: 'Mike',
  N: 'November',
  O: 'Oscar',
  P: 'Papa',
  Q: 'Quebec',
  R: 'Romeo',
  S: 'Sierra',
  T: 'Tango',
  U: 'Uniform',
  V: 'Victor',
  W: 'Whiskey',
  X: 'X-ray',
  Y: 'Yankee',
  Z: 'Zulu',
} as const;
const aviationDigits: Record<string, string> = {
  0: 'ZE-RO',
  1: 'WUN',
  2: 'TOO',
  3: 'TREE',
  4: 'FOW-er',
  5: 'FIFE',
  6: 'SIX',
  7: 'SEV-en',
  8: 'AIT',
  9: 'NIN-er',
} as const;

const inputRef = ref<InstanceType<typeof BFormInput | typeof BFormTextarea> | null>(null);
const scrollableRef = ref<HTMLElement | null>(null);
const scrollBottomRef = ref<HTMLElement | null>(null);

const aviationAlphabetLowerPartKeys = computed(() => {
  const keys = Object.keys(aviationAlphabet);
  const len = keys.length;
  return keys.slice(0, Math.floor(len / 2));
});

const aviationAlphabetUpperPartKeys = computed(() => {
  const keys = Object.keys(aviationAlphabet);
  const len = keys.length;
  return keys.slice(Math.floor(len / 2), len);
});

const aviationDigitsLowerPartKeys = computed(() => {
  const keys = Object.keys(aviationDigits);
  const len = keys.length;
  return keys.slice(0, Math.floor(len / 2));
});

const aviationDigitsUpperPartKeys = computed(() => {
  const keys = Object.keys(aviationDigits);
  const len = keys.length;
  return keys.slice(Math.floor(len / 2), len);
});

const alphabetInputConverted = computed(() => {
  return convertAccentedCharacters(alphabetInput.value);
});

function showModal() {
  // called by AppHeader
  isAviationSpellingModalVisible.value = true;
}

// function closeModal() {
//   isAviationSpellingModalVisible.value = false;
// }

const scrollToBottom = throttle(() => {
  if (cancelScroll.value) {
    cancelScroll.value();
  }
  if ($scrollTo && scrollBottomRef.value && scrollableRef.value) {
    cancelScroll.value = $scrollTo(scrollBottomRef.value, 0, {
      container: scrollableRef.value,
      cancelable: true,
    });
  }
}, 500);

function convertAccentedCharacters(strParam: string) {
  let str = strParam;
  const conversions = {
    // ae: 'ä|æ|ǽ',
    // oe: 'ö|œ',
    // ue: 'ü',
    // Ae: 'Ä',
    // Ue: 'Ü',
    // Oe: 'Ö',
    A: 'À|Á|Â|Ã|Ä|Å|Ǻ|Ā|Ă|Ą|Ǎ',
    a: 'à|á|â|ã|å|ǻ|ā|ă|ą|ǎ|ª',
    C: 'Ç|Ć|Ĉ|Ċ|Č',
    c: 'ç|ć|ĉ|ċ|č',
    D: 'Ð|Ď|Đ',
    d: 'ð|ď|đ',
    E: 'È|É|Ê|Ë|Ē|Ĕ|Ė|Ę|Ě',
    e: 'è|é|ê|ë|ē|ĕ|ė|ę|ě',
    G: 'Ĝ|Ğ|Ġ|Ģ',
    g: 'ĝ|ğ|ġ|ģ',
    H: 'Ĥ|Ħ',
    h: 'ĥ|ħ',
    I: 'Ì|Í|Î|Ï|Ĩ|Ī|Ĭ|Ǐ|Į|İ',
    i: 'ì|í|î|ï|ĩ|ī|ĭ|ǐ|į|ı',
    J: 'Ĵ',
    j: 'ĵ',
    K: 'Ķ',
    k: 'ķ',
    L: 'Ĺ|Ļ|Ľ|Ŀ|Ł',
    l: 'ĺ|ļ|ľ|ŀ|ł',
    N: 'Ñ|Ń|Ņ|Ň',
    n: 'ñ|ń|ņ|ň|ŉ',
    O: 'Ò|Ó|Ô|Õ|Ō|Ŏ|Ǒ|Ő|Ơ|Ø|Ǿ',
    o: 'ò|ó|ô|õ|ō|ŏ|ǒ|ő|ơ|ø|ǿ|º',
    R: 'Ŕ|Ŗ|Ř',
    r: 'ŕ|ŗ|ř',
    S: 'Ś|Ŝ|Ş|Š',
    s: 'ś|ŝ|ş|š|ſ',
    T: 'Ţ|Ť|Ŧ',
    t: 'ţ|ť|ŧ',
    U: 'Ù|Ú|Û|Ũ|Ū|Ŭ|Ů|Ű|Ų|Ư|Ǔ|Ǖ|Ǘ|Ǚ|Ǜ',
    u: 'ù|ú|û|ũ|ū|ŭ|ů|ű|ų|ư|ǔ|ǖ|ǘ|ǚ|ǜ',
    Y: 'Ý|Ÿ|Ŷ',
    y: 'ý|ÿ|ŷ',
    W: 'Ŵ',
    w: 'ŵ',
    Z: 'Ź|Ż|Ž',
    z: 'ź|ż|ž',
    // AE: 'Æ|Ǽ',
    // ss: 'ß',
    // IJ: 'Ĳ',
    // ij: 'ĳ',
    // OE: 'Œ',
    // f: 'ƒ',
  } as const;

  Object.keys(conversions).forEach((key) => {
    const re = new RegExp(conversions[key as keyof typeof conversions], 'g');
    str = str.replace(re, key);
  });
  return str;
}

function clearInput() {
  alphabetInput.value = '';
  inputRef.value?.focus();
}

watch(alphabetInputConverted, () => {
  scrollToBottom();
});

onUnmounted(() => {
  // cancel the throttle timer when the component is removed
  scrollToBottom.cancel();
});

defineExpose({
  showModal,
});
</script>

<style
  lang="scss"
  scoped
>
.initial-letter-and-word-container {
  display: flex;
}

.initial-letter {
  color: var(--accent-color);
  margin-right: $gap-0_38x;

  width: 1vw;
  text-align: center;
  font-weight: bold;

  .letter-list & {
    .theme-v3e & {
      color: var(--text-color-1);
      font-weight: bold;
    }
  }
}

.scrollable {
  max-height: 50vh;
  overflow-y: auto;
}

.top-banner-image {
  margin: calc(#{$gap-1x} * -1) calc(#{$gap-1x} * -1) $gap-1x calc(#{$gap-1x} * -1);
  height: 10vw;

  background: url('./assets/IMP_2019_Pic_Spelling-Generator_001.png') no-repeat center top;
  background-size: cover;
  position: relative;

  .text {
    position: absolute;
    bottom: 0;
    color: var(--text-color-1);
    font-size: $font-20px;
    font-weight: bold;
    margin: 0 0 $gap-0_62x $gap-1x;
  }
}
</style>
