// Pinia Store
import { computed, inject, ref } from 'vue';
import type { Router } from 'vue-router';
import { AxiosError, type AxiosResponse, type AxiosStatic } from 'axios';
import { useThrottleFn } from '@vueuse/core';

// import throttle from 'lodash.throttle';
import { defineStore } from 'pinia';

import { useAuthStore } from '@/stores/auth';
import { type EntityBlockRaw, useEntitiesStore } from '@/stores/entities';
import { useGlobalStore } from '@/stores/global';
import { useImpulsesStore } from '@/stores/impulses';
import { useTranslationStore } from '@/stores/translation';

import { useToast } from '@/composables/useToast';

import globalConstants from '@/globalConstants';

const {
  ALL_ROLES,
  USER_JOURNEY_TALENT_MANUAL_PAGES,
  USER_JOURNEY_V3_PAGES,
  ISO_ALPHA2_TO_AIRCRAFT_REGISTRATION_PREFIXES_MAP,
} = globalConstants;

console.log('Pinia Account store is being created.'); // no access to Vue.prototype.$log here yet

// Feature detect + local reference
let storage: Storage | null;
let fail;
let uid;
try {
  uid = new Date().toString();
  (storage = window.localStorage).setItem(uid, uid);
  fail = storage.getItem(uid) !== uid.toString();
  storage.removeItem(uid);
  if (fail) {
    storage = null;
  }
} catch (exception) {
  const exceptionName = exception && typeof exception === 'object' && 'name' in exception && exception.name;
  console.log(`Could not access the local storage: ${exceptionName}`);
}
//

let router: Router;

// const BACKGROUND_TYPES = {
//   SYSTEM_GRADIENT: 'SYSTEM_GRADIENT',
//   SYSTEM_IMAGE: 'SYSTEM_IMAGE',
//   USER_IMAGE: 'USER_IMAGE',
// };

function clearStorage() {
  if (!storage) {
    console.warn('Storage is not available.');
    return;
  }

  const keysToRemove = [
    'user-journey-setup',
    'user-role',
    // Old keys
    'role',
    'uj-demo-mode',
    'uj-review-mode',
    'uj-page',
  ];

  keysToRemove.forEach((key) => storage.removeItem(key));
}

export interface ProfileCountry {
  aircraft_code: string;
  code: string;
  id: number;
  name: string;
}

interface ProfileActions {
  allow_account_management: boolean;
  allow_client_share?: boolean; // JGO
  allow_freely_impulze_selection: boolean;
  allow_invitation_management: boolean;
  can_access_digital_talent_manual: boolean;
  can_access_digital_talent_manual_from_others: boolean;
  can_access_group_dynamics: boolean;
  can_access_help_requests: boolean;
  can_access_impulze_area: boolean;
  can_access_individual_empowerment: boolean;
  can_access_invitations: boolean;
  can_access_knowledge_base: boolean;
  can_access_logbook: boolean;
  can_access_logbook_archive: boolean;
  can_access_scenario_manager: boolean;
  can_access_six_pack: boolean;
  can_access_talent_manual: boolean;
  can_access_tutorial_nuggets: boolean;
}

export interface ProfileRole {
  actions: null | ProfileActions;
  id: number;
  name: string;
}

export interface ProfileClient {
  avatar: string;
  display_name: string;
  id: number;
  inserted_at: string;
  is_consulting: boolean;
}

export interface ProfileRaw {
  about: null | string;
  actions: ProfileActions;
  avatar: null | string;
  avatar_id: null | string;
  birth_day: null | number;
  birth_month: null | number;
  birth_year: null | number;
  business_card: null | string;
  can_access_group_dynamics: boolean;
  can_access_invitations: boolean;
  can_access_scenario_manager: boolean;
  can_access_six_pack: boolean;
  client: null | ProfileClient;
  confirmed: boolean;
  country_nationality: null | ProfileCountry;
  country_origin: null | ProfileCountry;
  country_residence: null | ProfileCountry;
  country_work: null | ProfileCountry;
  custom_contents: {
    id: number;
    media: null | string;
    media_url: null;
    order: number;
    text: string;
    type: {
      id: 1 | 2 | 3;
      name: 'text' | 'video' | 'image';
    };
  }[];
  default_language_id: number;
  email: string;
  entity_block: EntityBlockRaw;
  first_name: string;
  function: null;
  gender_id: number;
  has_active_cockpit: boolean;
  has_talent_manual: boolean;
  id: number;
  inserted_at: string;
  is_avatar: boolean;
  is_example_user: boolean;
  is_service_contact: boolean;
  is_talent_manual_user: boolean;
  is_temporary_account: boolean;
  last_name: null | string;
  mask: null | string;
  mother_language_id: number;
  native_language_id: number;
  nickname: null | string;
  onboarding_step: number;
  personal_avatar: null | string;
  personal_email: null;
  position: null | string;
  public_contact: null | string;
  public_details: null | {
    about: string;
    address: string;
    bg_image: string;
    business_card: null;
    business_phone: string;
    company_logo: string;
    email: string;
    fields: string;
    function: string;
    mobile_phone: string;
    title: string;
    website: string;
  };
  public_email: null | string;
  receive_invitation_cc: boolean;
  roles: ProfileRole[];
  service_contact_order: null | number;
  settings: null | {
    is_example_user: boolean;
    is_service_contact: boolean;
    receive_invitation_cc: boolean;
    service_contact_order: null | number;
  };
  system_language_id: number;
  timezone: null | string;
  username: string;
  website: null;
  //
  // Local properties, not on API response:
  //
  ownProfile: boolean;
  aircraftRegistrationPrefix: string;
}

export type Profile = ProfileRaw & {
  ownProfile: true;
};

export interface SettingsRaw {
  bg_color: null;
  bg_image: null;
  id: number;
  is_example_user: boolean;
  is_public_talent_manual: boolean;
  is_service_contact: boolean;
  receive_invitation_cc: boolean;
  replace_crew_member_id: null | number;
  replace_pilot_id: null | number;
  selected_profile_id: number;
  service_contact_order: null;
  system_language_id: number;
  timezone: null | string;
  user_image: {
    image: null | string;
    thumb: null | string;
  };
}

export interface UserRole {
  id: number;
  code: string;
  name: string;
}

interface UserJourneySetup {
  mode?: string;
  phase: number;
  step: number;
  userRoleId?: undefined | number;
  originalUserRoleId?: number;
  returnPage: undefined | string;
  isTalentManual?: boolean;
  isJourneyV3?: boolean;
}

export enum BackgroundTypes {
  SYSTEM_GRADIENT = 'SYSTEM_GRADIENT',
  SYSTEM_IMAGE = 'SYSTEM_IMAGE',
  USER_IMAGE = 'USER_IMAGE',
}

interface BackgroundSystemImage {
  id: number;
  image: string;
  thumb: string;
  title: string;
}
interface BackgroundSystemGradient {
  code: string;
  id: number;
  name: string;
}
export interface BackgroundUserImage {
  image: null | string;
  thumb: null | string;
}
export type BackgroundImageOrColorOrUserImage = BackgroundSystemGradient | BackgroundSystemImage | BackgroundUserImage;

export interface SystemAvatar {
  avatar: string; // URL
  gender: { id: number; name: string };
  id: number;
}

export interface CoTeamLeader {
  avatar: string;
  first_name: string;
  has_talent_manual: boolean;
  id: number;
  is_avatar: boolean;
  last_name: string;
  mask: string;
  nickname: string;
}

let lastCoPilotPilotsFetchTime: number;

interface InvitationInfoPayload {
  token: string;
  email?: undefined | string;
  name?: undefined | string;
  firstName?: undefined | string;
  lastName?: undefined | string;
  roleId?: undefined | number;
  isTalentManual?: undefined | boolean;
  allowQualityCheck?: undefined | boolean;
  isJourneyV3?: boolean;
  job?: undefined | {
    id: number;
    description: string;
    title: string;
    inviter: {
      id: number;
      nickname: string;
      first_name: string;
      last_name: string;
      avatar: string;
    };
  };
}

const hasRole = (userRoles: ProfileRole[], role: UserRole) => role && Array.isArray(userRoles) && userRoles.some((userRole) => userRole.id === role.id);

function injectRouter(routerParam: Router) {
  router = routerParam;
}

function setTimeZoneMismatchLastShown() {
  if (storage) {
    storage.setItem('time-zone-mismatch-last-shown', new Date().toString());
  }
}

export const useAccountStore = defineStore('account', () => {
  // const router = useRouter(); // not working, will be injected via action
  const $log: any = inject('$log');
  const $http: undefined | AxiosStatic = inject('$http');
  const toast = useToast();

  // setTimeout(() => {
  //   toast.showToastInfo('Info');
  //   toast.showToastSuccess('Success');
  //   toast.showToastError('Error');
  // });

  //
  //
  // State
  //
  //
  const profile = ref<null | Profile>(null);
  const settings = ref<null | SettingsRaw>(null);
  const allRoles = ref<typeof ALL_ROLES>(ALL_ROLES);
  const userRole = ref<UserRole>(ALL_ROLES.NONE);
  const availableBackgrounds = ref<{
    [BackgroundTypes.SYSTEM_GRADIENT]: BackgroundSystemGradient[]; // /config/colors/bg
    [BackgroundTypes.SYSTEM_IMAGE]: BackgroundSystemImage[]; // /config/images/bg
    [BackgroundTypes.USER_IMAGE]: [] | [BackgroundUserImage]; // /session/settings
  }>({
    [BackgroundTypes.SYSTEM_GRADIENT]: [],
    [BackgroundTypes.SYSTEM_IMAGE]: [],
    [BackgroundTypes.USER_IMAGE]: [],
  });
  const currentBackground = ref<null | BackgroundImageOrColorOrUserImage>(null);
  const availableAvatars = ref<SystemAvatar[]>([]);
  // const selectedAvatarId = ref(null);
  const timeZoneMismatch = ref<null | {
    browser: string;
    settings: null | string;
  }>(null);
  const userJourney = ref<{
    exited: boolean;
    // demoModeUserRole: boolean;
    // reviewModeUserRole: boolean;
  }>({
    exited: false,
    // demoModeUserRole: false,
    // reviewModeUserRole: false,
  });
  const userJourneySetup = ref<null | UserJourneySetup>(null);
  const invitationInfo = ref<null | InvitationInfoPayload>(null);
  const coPilotPilots = ref<CoTeamLeader[]>([]);
  const selectedCoPilotPilot = ref<null | CoTeamLeader>(null);
  //
  //
  // Getters
  //
  //
  const userProfile = computed((): null | Profile => profile.value);
  const userId = computed((): null | number => profile.value && profile.value.id);
  const userHasTalentManual = computed((): boolean => {
    if (!profile.value || !('has_talent_manual' in profile.value)) {
      return false;
    }
    return profile.value.has_talent_manual === true;
  });
  const userHasActiveCockpit = computed((): boolean => {
    if (!profile.value || !('has_active_cockpit' in profile.value)) {
      return false;
    }
    return !!profile.value.has_active_cockpit;
  });
  const userRoles = computed((): ProfileRole[] => {
    if (!profile.value || !('roles' in profile.value) || profile.value.roles.length === 0) {
      return [{
        id: ALL_ROLES.NONE.id,
        name: 'No role',
        actions: null,
      }];
    }
    return profile.value.roles;
  });
  const userRolesIds = computed((): number[] => {
    if (!Array.isArray(userRoles.value)) {
      return [];
    }
    return userRoles.value.map((role) => role.id);
  });
  const userRoleCode = computed(() => userRole.value && userRole.value.code);
  const userRoleId = computed(() => userRole.value && userRole.value.id);
  const roleHasAction = computed(() => <K extends keyof ProfileActions>(action: K): boolean => {
    if (!profile.value) {
      return false;
    }
    if (!userRole.value) {
      // No userRole defined yet.
      return false;
    }
    if (!userRole.value.id) {
      $log.debug('roleHasAction: userRole.value has no ID?', userRole.value);
      return false;
    }
    //
    // No role? Check the profile actions instead of role-based
    //
    if (userRole.value.id === ALL_ROLES.NONE.id && 'actions' in profile.value) {
      return profile.value.actions[action] === true;
    }
    //
    // Has role with ID
    //
    if (!('roles' in profile.value)) {
      // No roles defined yet.
      return false;
    }
    //
    // Has roles in profile
    //
    const selectedRole = profile.value.roles.find((role: ProfileRole) => role.id === userRole.value.id);
    if (!selectedRole) {
      $log.debug(`roleHasAction: did not find selected role (${userRole.value.id}) in profile roles`, profile.value.roles);
      // Admin going through the GetReady Program demo; allow "can_access_talent_manual" to show the Quality Check pages:
      return !!(Array.isArray(userRoles.value) && userRoles.value.length && userRoles.value[0].id === ALL_ROLES.ADMIN.id && action === 'can_access_talent_manual');
    }
    if (!('actions' in selectedRole) || selectedRole.actions === null) {
      $log.debug(`roleHasAction: selected role (${selectedRole.id}) has no actions`, selectedRole);
      return false;
    }
    //
    // Has actions object
    //
    if (!(action in selectedRole.actions)) {
      $log.debug(`roleHasAction: selected role actions has no action "${action}"`, selectedRole.actions);
      return false;
    }
    $log.debug(`roleHasAction: ${action}: ${selectedRole.actions[action]}`);
    return selectedRole.actions[action] === true;
  });
  const userCanAccessInvitations = computed((): boolean => roleHasAction.value('can_access_invitations'));
  const userCanAccessSixPack = computed((): boolean => roleHasAction.value('can_access_six_pack'));
  const userCanAccessGroupDynamics = computed((): boolean => roleHasAction.value('can_access_group_dynamics'));
  const userCanAccessScenarioManager = computed((): boolean => roleHasAction.value('can_access_scenario_manager'));
  const userCanAccessTalentManual = computed((): boolean => roleHasAction.value('can_access_talent_manual'));
  // const userRoleHasAction = computed(() => (payload: string): boolean => roleHasAction(state, payload),
  // const userRoleHasAction = computed(() => (actionName: string) => boolean => {
  //   return (actionName: string): boolean => {
  //     return roleHasAction.value(this, actionName);
  //   }
  // });
  const userRoleHasAction = computed(() => <K extends keyof ProfileActions>(action: K): boolean => roleHasAction.value(action));
  //
  const userRoleIsNone = computed(() => ALL_ROLES.NONE && userRole.value && userRole.value.id === ALL_ROLES.NONE.id);
  const userRoleIsAdministrator = computed((): boolean => ALL_ROLES.ADMIN && userRole.value && userRole.value.id === ALL_ROLES.ADMIN.id);
  const userHasAdministratorRole = computed(() => hasRole(userRoles.value, ALL_ROLES.ADMIN));
  const userRoleIsTalentManual = computed((): boolean => ALL_ROLES.TALENT_MANUAL && userRole.value && userRole.value.id === ALL_ROLES.TALENT_MANUAL.id);
  //
  // IMP
  //
  const userRoleIsImpLAndDManager = computed((): boolean => ALL_ROLES.IMP_L_AND_D_MANAGER && userRole.value && userRole.value.id === ALL_ROLES.IMP_L_AND_D_MANAGER.id);
  const userHasImpLAndDManagerRole = computed(() => hasRole(userRoles.value, ALL_ROLES.IMP_L_AND_D_MANAGER));
  const userRoleIsImpTeamLeader = computed((): boolean => ALL_ROLES.IMP_TEAM_LEADER && userRole.value && userRole.value.id === ALL_ROLES.IMP_TEAM_LEADER.id);
  const userHasImpTeamLeaderRole = computed(() => hasRole(userRoles.value, ALL_ROLES.IMP_TEAM_LEADER));
  const userRoleIsImpTeamMember = computed((): boolean => ALL_ROLES.IMP_TEAM_MEMBER && userRole.value && userRole.value.id === ALL_ROLES.IMP_TEAM_MEMBER.id);
  const userHasImpTeamMemberRole = computed(() => hasRole(userRoles.value, ALL_ROLES.IMP_TEAM_MEMBER));
  const userRoleIsImpConsultant = computed((): boolean => ALL_ROLES.IMP_CONSULTANT && userRole.value && userRole.value.id === ALL_ROLES.IMP_CONSULTANT.id);
  const userHasImpConsultantRole = computed(() => hasRole(userRoles.value, ALL_ROLES.IMP_CONSULTANT));
  const userRoleIsImpCoTeamLeader = computed((): boolean => ALL_ROLES.IMP_CO_TEAM_LEADER && userRole.value && userRole.value.id === ALL_ROLES.IMP_CO_TEAM_LEADER.id);
  const userHasImpCoTeamLeaderRole = computed(() => hasRole(userRoles.value, ALL_ROLES.IMP_CO_TEAM_LEADER));
  //
  // JGO
  //
  const userRoleIsJgoPartner = computed((): boolean => ALL_ROLES.JGO_PARTNER && userRole.value && userRole.value.id === ALL_ROLES.JGO_PARTNER.id);
  const userHasJgoPartnerRole = computed(() => hasRole(userRoles.value, ALL_ROLES.JGO_PARTNER));
  const userRoleIsJgoParticipant = computed((): boolean => ALL_ROLES.JGO_PARTICIPANT && userRole.value && userRole.value.id === ALL_ROLES.JGO_PARTICIPANT.id);
  // const userHasJgoParticipantRole = computed(() => {
  //   return hasRole(userRoles.value, ALL_ROLES.JGO_PARTICIPANT);
  // });
  //
  // TLY
  //
  const userRoleIsTlyHr = computed((): boolean => ALL_ROLES.TLY_HR && userRole.value && userRole.value.id === ALL_ROLES.TLY_HR.id);
  const userHasTlyHrRole = computed(() => hasRole(userRoles.value, ALL_ROLES.TLY_HR));
  const userRoleIsTlyTeamLeader = computed((): boolean => ALL_ROLES.TLY_TEAM_LEADER && userRole.value && userRole.value.id === ALL_ROLES.TLY_TEAM_LEADER.id);
  const userHasTlyTeamLeaderRole = computed(() => hasRole(userRoles.value, ALL_ROLES.TLY_TEAM_LEADER));
  const userRoleIsTlyCandidate = computed((): boolean => ALL_ROLES.TLY_CANDIDATE && userRole.value && userRole.value.id === ALL_ROLES.TLY_CANDIDATE.id);
  const userHasTlyCandidateRole = computed(() => hasRole(userRoles.value, ALL_ROLES.TLY_CANDIDATE));
  const userRoleIsTlyHrAdmin = computed((): boolean => ALL_ROLES.TLY_HR_ADMIN && userRole.value && userRole.value.id === ALL_ROLES.TLY_HR_ADMIN.id);
  const userHasTlyHrAdminRole = computed(() => hasRole(userRoles.value, ALL_ROLES.TLY_HR_ADMIN));
  const userRoleIsTlyTeamMember = computed((): boolean => ALL_ROLES.TLY_TEAM_MEMBER && userRole.value && userRole.value.id === ALL_ROLES.TLY_TEAM_MEMBER.id);
  const userHasTlyTeamMemberRole = computed(() => hasRole(userRoles.value, ALL_ROLES.TLY_TEAM_MEMBER));
  //
  // ZLY
  //
  const userRoleIsZlyTeamLeader = computed((): boolean => ALL_ROLES.ZLY_TEAM_LEADER && userRole.value && userRole.value.id === ALL_ROLES.ZLY_TEAM_LEADER.id);
  const userHasZlyTeamLeaderRole = computed(() => hasRole(userRoles.value, ALL_ROLES.ZLY_TEAM_LEADER));
  const userRoleIsZlyTeamMember = computed((): boolean => ALL_ROLES.ZLY_TEAM_MEMBER && userRole.value && userRole.value.id === ALL_ROLES.ZLY_TEAM_MEMBER.id);
  const userHasZlyTeamMemberRole = computed(() => hasRole(userRoles.value, ALL_ROLES.ZLY_TEAM_MEMBER));
  //
  //
  const isTalentManualUser = computed((): boolean => Boolean(profile.value && profile.value.is_talent_manual_user === true));
  const isAllowedToDownloadTalentManual = computed((): boolean => roleHasAction.value('can_access_digital_talent_manual'));
  const isAllowedToDownloadOthersTalentManual = computed((): boolean => roleHasAction.value('can_access_digital_talent_manual_from_others'));
  // const userJourneySetup = computed(() =>: (state: state): boolean => userJourneySetup.value,
  const userJourneySetupIsDemoMode = computed((): boolean => Boolean(userJourneySetup.value && userJourneySetup.value.mode === 'demo'));
  const userJourneySetupIsReviewMode = computed((): boolean => Boolean(userJourneySetup.value && userJourneySetup.value.mode === 'review'));
  const currentUserJourneyPage = computed((): null | number => {
    if (userJourneySetupIsDemoMode.value || userJourneySetupIsReviewMode.value) {
      return userJourneySetup.value && userJourneySetup.value.step;
    }
    if (profile.value && 'onboarding_step' in profile.value) {
      // check if "1" (what is saved on backend when the user creates the account)
      if (profile.value.onboarding_step === 1) {
        // go to the first page after registration
        const globalStore = useGlobalStore();
        if (globalStore.isThemeV3) {
          return USER_JOURNEY_V3_PAGES.PHASE_2.P1_COMPLETE_YOUR_ACCOUNT; // the component will check if is authenticated, to show the profile form instead of the registration form
        }
        if (isTalentManualUser.value) {
          return USER_JOURNEY_TALENT_MANUAL_PAGES.PHASE_2.ACCOUNT_COMPLETION;
        }
        return USER_JOURNEY_V3_PAGES.PHASE_2.P1_COMPLETE_YOUR_ACCOUNT; // TODO clean-up
      }
      return profile.value.onboarding_step;
    }
    return null;
  });
  const lastUserJourneyStepId = computed((): number => {
    if (isTalentManualUser.value) {
      return Object.values(Object.values(USER_JOURNEY_TALENT_MANUAL_PAGES).pop() as object).pop() as number;
    }
    return USER_JOURNEY_V3_PAGES.PHASE_2.P4_PHASE_4_COMPLETED;
  });
  const userFinishedUserJourney = computed((): boolean => {
    if (!profile.value || !('onboarding_step' in profile.value)) {
      return false;
    }
    return profile.value.onboarding_step === lastUserJourneyStepId.value;
  });
  /* const userFullName = computed((state: state): string => {
    if (!('profile' in state)) {
      return '';
    }
    let fullName = '';
    if ('first_name' in profile.value) {
      fullName = `${profile.value.first_name} `;
    }
    if ('last_name' in profile.value) {
      fullName += profile.value.last_name;
    }
    return fullName.trim();
  }); */
  /* const userFirstName = computed((state: state): string => {
    if (!('profile' in state)) {
      return '';
    }
    if ('first_name' in profile.value) {
      return profile.value.first_name.trim();
    }
    return '';
  }); */
  const userAvatarUrl = computed((): string => {
    if (profile.value && profile.value.avatar) {
      return profile.value.avatar.trim();
    }
    return '';
  });
  const userPersonalAvatarUrl = computed((): string => {
    if (profile.value && profile.value.personal_avatar) {
      return profile.value.personal_avatar.trim();
    }
    return '';
  });
  const availableBackgroundColors = computed((): BackgroundSystemGradient[] => availableBackgrounds.value[BackgroundTypes.SYSTEM_GRADIENT]);
  const availableBackgroundImages = computed((): BackgroundSystemImage[] => availableBackgrounds.value[BackgroundTypes.SYSTEM_IMAGE]);
  const availableBackgroundsCustom = computed((): [] | [BackgroundUserImage] => availableBackgrounds.value[BackgroundTypes.USER_IMAGE]);
  const selectedAvatarId = computed((): null | string => {
    if (profile.value && 'avatar_id' in profile.value) {
      return profile.value.avatar_id;
    }
    return null;
  });
  const userTimezone = computed((): null | string => {
    if (!settings.value || !('timezone' in settings.value)) {
      return null;
    }
    return settings.value.timezone;
  });
  const exitedUserJourney = computed((): boolean => userJourney.value.exited);
  const isTemporaryAccount = computed((): boolean => {
    if (profile.value && 'is_temporary_account' in profile.value) {
      return profile.value.is_temporary_account;
    }
    return false;
  });
  const timeZoneMismatchLastShown = computed((): null | string => {
    if (storage) {
      return storage.getItem('time-zone-mismatch-last-shown');
    }
    return null;
  });

  //
  //
  // Actions
  //
  //
  // function resetAllStores() {
  //   const activePinia = getActivePinia();
  //   if (activePinia) {
  //     Object.entries(activePinia.state.value).forEach(([storeName, state]) => {
  //       const storeDefinition = defineStore(storeName, state);
  //       const store = storeDefinition(activePinia);
  //       store.$reset();
  //     });
  //   }
  // }

  function resetState() {
    profile.value = null;
    settings.value = null;
    allRoles.value = ALL_ROLES;
    userRole.value = ALL_ROLES.NONE;
    availableBackgrounds.value = {
      [BackgroundTypes.SYSTEM_GRADIENT]: [],
      [BackgroundTypes.SYSTEM_IMAGE]: [],
      [BackgroundTypes.USER_IMAGE]: [],
    };
    currentBackground.value = null;
    availableAvatars.value = [];
    // selectedAvatarId.value = ref(null;
    timeZoneMismatch.value = null;
    userJourney.value = {
      exited: false,
      // demoModeUserRole: false,
      // reviewModeUserRole: false,
    };
    userJourneySetup.value = null;
    invitationInfo.value = null;
    coPilotPilots.value = [];
    selectedCoPilotPilot.value = null;
    //
    clearStorage();
  }

  function setLocalUserRoleFromId(payload: undefined | number) {
    if (payload === undefined) {
      $log.debug('SET_USER_ROLE_FROM_ID: payload is undefined; selecting role "NONE"');
      userRole.value = ALL_ROLES.NONE;
      if (storage) {
        storage.removeItem('user-role');
      }
      return;
    }
    const foundRole = Object.values(ALL_ROLES).find((role) => role.id === payload);
    if (foundRole) {
      userRole.value = foundRole;
      if (storage) {
        storage.setItem('user-role', foundRole.id.toString());
      }
    } else {
      $log.error(`Role code with ID "${payload}" not found`);
    }
  }

  function setInvitationInfo(payload: InvitationInfoPayload) {
    setLocalUserRoleFromId(payload.roleId);
    invitationInfo.value = payload;
  }

  function saveUserJourneySetup(payload: UserJourneySetup) {
    userJourneySetup.value = payload;
    if (storage) {
      storage.setItem('user-journey-setup', JSON.stringify(payload));
    }
  }

  function enterUserJourneyTalentManual({
    mode = 'demo',
    phase = 1,
    step = 0,
    userRoleId: userRoleIdArg = ALL_ROLES.NONE.id,
    returnPage,
  }: UserJourneySetup) {
    // Find the step ID (because if 0 will be the first step of the phase)
    let stepId = step;
    switch (phase) {
      case 1:
        stepId = stepId || USER_JOURNEY_TALENT_MANUAL_PAGES.PHASE_1.WELCOME;
        break;
      case 2:
        stepId = stepId || USER_JOURNEY_TALENT_MANUAL_PAGES.PHASE_2.ACCOUNT_COMPLETION;
        break;
      case 3:
        stepId = stepId || USER_JOURNEY_TALENT_MANUAL_PAGES.PHASE_3.TALENT_MANUAL_BENEFITS;
        break;
      case 4:
        stepId = stepId || USER_JOURNEY_TALENT_MANUAL_PAGES.PHASE_4.CONGRATULATIONS;
        break;
        // no default
    }

    if (phase === 1) {
      const token = 'demo-mode';
      setInvitationInfo({ token, roleId: userRoleIdArg, isTalentManual: true }); // this will set the user role
    } else {
      setLocalUserRoleFromId(userRoleIdArg);
    }

    const setup = {
      mode,
      phase,
      step: stepId,
      userRoleId: userRoleIdArg,
      originalUserRoleId: userRole.value.id,
      returnPage,
      isTalentManual: true,
    };
    saveUserJourneySetup(setup);
    router.push({ name: `OnboardingTalentManualPhase${phase}` });
  }

  function enterUserJourneyV3({
    mode = 'demo',
    phase = 1,
    step = 0,
    userRoleId: userRoleIdArg = ALL_ROLES.NONE.id,
    returnPage,
  }: UserJourneySetup) {
    // Find the step ID (because if 0 will be the first step of the phase)
    let stepId = step;
    switch (phase) {
      case 1:
        stepId = stepId || USER_JOURNEY_V3_PAGES.PHASE_2.P1_WELCOME;
        break;
      case 2:
        stepId = stepId || USER_JOURNEY_V3_PAGES.PHASE_2.P1_WELCOME;
        break;
      case 3:
        stepId = stepId || USER_JOURNEY_V3_PAGES.PHASE_2.P1_WELCOME;
        break;
      case 4:
        stepId = stepId || USER_JOURNEY_V3_PAGES.PHASE_2.P1_WELCOME;
        break;
        // no default
    }

    if (phase === 1) {
      const token = 'demo-mode';
      setInvitationInfo({ token, roleId: userRoleIdArg, isJourneyV3: true }); // this will set the user role
    } else {
      setLocalUserRoleFromId(userRoleIdArg);
    }

    const setup = {
      mode,
      phase,
      step: stepId,
      userRoleId: userRoleIdArg,
      originalUserRoleId: userRole.value.id,
      returnPage,
      isJourneyV3: true,
    };
    saveUserJourneySetup(setup);
    router.push({ name: 'UserJourneyV3' });
  }

  function readStuffFromLocalStorage() {
    const authStore = useAuthStore();
    if (!authStore.isAuthenticated) {
      // Clear storage
      clearStorage();
      return;
    }

    if (!storage) {
      return;
    }

    const routeIsInvitationOrGetReadyProgramWithToken = ['/invitation', '/get-ready-program'].includes(window.location.pathname) && Boolean(new URLSearchParams(window.location.search).get('token'));
    if (routeIsInvitationOrGetReadyProgramWithToken) {
      // New invitation, ignore the existing user-journey-setup
      return;
    }

    const userJourneySetupString = storage.getItem('user-journey-setup');
    if (userJourneySetupString) {
      const userJourneySetupFromStorage = JSON.parse(userJourneySetupString);
      if (userJourneySetupFromStorage) {
        // Should enter User Journey
        if (userJourneySetupFromStorage.isJourneyV3) {
          enterUserJourneyV3(userJourneySetupFromStorage);
        } else if (userJourneySetupFromStorage.isTalentManual) {
          enterUserJourneyTalentManual(userJourneySetupFromStorage);
        }
      }
    }
  }

  function setLocalUserRole(payload: UserRole) {
    userRole.value = payload;
    if (storage) {
      storage.setItem('user-role', payload.id.toString());
    }
  }

  function checkAndFixUserRole() {
    if (userRole.value && userRolesIds.value.includes(userRole.value.id)) {
      // All OK.
      return;
    }

    $log.info(`User is using a role they don’t own (${userRole.value && userRole.value.id}). Fixing.`);
    //
    // Rules for selecting the role:
    //
    // - use the role from local store if valid
    // - else if the user has Pilot, select it
    // - else if the user has Co-Pilot, select it
    // - else if the user has Crew Member, select it
    // - else select the first
    //

    const getNextValidRole = () => {
      //
      // These three roles are by order of "importance"
      //
      if (ALL_ROLES.IMP_TEAM_LEADER && userRoles.value.some((role) => role.id === ALL_ROLES.IMP_TEAM_LEADER.id)) {
        $log.debug('[SET USER ROLE] Next valid role: Pilot');
        return ALL_ROLES.IMP_TEAM_LEADER;
      }
      if (ALL_ROLES.IMP_CO_TEAM_LEADER && userRoles.value.some((role) => role.id === ALL_ROLES.IMP_CO_TEAM_LEADER.id)) {
        $log.debug('[SET USER ROLE] Next valid role: Co-Pilot');
        return ALL_ROLES.IMP_CO_TEAM_LEADER;
      }
      if (ALL_ROLES.IMP_TEAM_MEMBER && userRoles.value.some((role) => role.id === ALL_ROLES.IMP_TEAM_MEMBER.id)) {
        $log.debug('[SET USER ROLE] Next valid role: Crew Member');
        return ALL_ROLES.IMP_TEAM_MEMBER;
      }
      //
      //
      // Not one of those three, get first role from ALL_ROLES if the user has any role
      // Filter user roles by existing ones (discard the ones we don’t know about)
      const validUserRoles = userRoles.value
        .filter((userRoleArg) => Object.values(ALL_ROLES)
          .some((systemRole) => systemRole.id === userRoleArg.id));
      if (validUserRoles.length) {
        const userRoleTmp = Object.values(ALL_ROLES).find((r) => r.id === validUserRoles[0].id);
        if (userRoleTmp) {
          $log.debug('[SET USER ROLE] Next valid role (first from user roles):', userRoleTmp);
          return userRoleTmp; // first role
        }
      }
      $log.warn('Could not find role');
      return ALL_ROLES.NONE;
    };
    //
    // const roleIdFromStorageString = storage.getItem('role');
    // let roleIdFromStorage;
    // if (roleIdFromStorageString) {
    //   roleIdFromStorage = parseInt(roleIdFromStorageString, 10);
    // }
    // if (roleIdFromStorage !== undefined) {
    //   $log.debug('[SET USER ROLE] From storage:', roleIdFromStorage);
    //   // Check that the selected role exists for this user
    //   const foundRole = userRoles.value.find(role => role.id === roleIdFromStorage);
    //   if (foundRole) {
    //     $log.debug('[SET USER ROLE] From storage exists for user');
    //     dispatch('setLocalUserRoleFromId', roleIdFromStorage);
    //   } else {
    //     $log.debug('[SET USER ROLE] From storage does not exist for user, get next valid');
    //     dispatch('setLocalUserRole', getNextValidRole());
    //   }
    // } else {
    //   $log.debug('[SET USER ROLE] Not found on storage, get next valid.');
    setLocalUserRole(getNextValidRole());
    // }
  }

  async function updateOnboardingStep(payload: number) {
    if (userJourneySetup.value) {
      if (userJourneySetup.value.mode === 'demo') {
        $log.info(`[Demo mode] Step: ${payload}`);
      } else if (userJourneySetup.value.mode === 'review') {
        $log.info(`[Review mode] Step: ${payload}`);
      } else {
        $log.warn(`Unknown mode: ${userJourneySetup.value.mode}`);
      }
      // Update user journey step on demo/review mode
      const step = payload;
      const phase = Number.parseInt(step.toString()[0], 10); // 1005 = 1, 2005 = 2, etc.
      saveUserJourneySetup({ ...userJourneySetup.value, phase, step });
      //
      //
      // Stop right here.
      // resolve(); // resolve to allow changing between phases
      return;
      //
      //
      //
    }

    $log.info(`[Default mode] Step: ${payload} [role: ${userRole.value && userRole.value.name}]`);

    $log.debug(`updateOnboardingStep action. Payload: ${payload}. Returning the promise`);

    // Check if we should register the user journey step on backend
    let registerStepOnBackend = true;
    if (userFinishedUserJourney.value) {
      registerStepOnBackend = false;
    }
    const globalStore = useGlobalStore();
    if (globalStore.isThemeV3) {
      if (payload < USER_JOURNEY_V3_PAGES.PHASE_2.P1_COMPLETE_YOUR_ACCOUNT) {
        // user has no account yet
        registerStepOnBackend = false;
      } else {
        // ok, register
      }
    } else if (globalStore.isAppJgo && payload < USER_JOURNEY_TALENT_MANUAL_PAGES.PHASE_2.ACCOUNT_COMPLETION) {
      registerStepOnBackend = false;
    }

    if (userFinishedUserJourney.value) {
      $log.info('Not registering user journey steps server side (user journey already finished)');
    }

    const authStore = useAuthStore();
    if (!authStore.isAuthenticated) {
      let message;

      if (payload < USER_JOURNEY_TALENT_MANUAL_PAGES.PHASE_2.ACCOUNT_COMPLETION) {
        message = `Trying to update onboarding step (${payload}, phase 1) but is not authenticated. Skipping.`;
        $log.debug(message);
        // do not reject
        // resolve();
        return;
      }

      message = `Trying to update onboarding step (${payload}, phase > 1!) but is not authenticated!`;
      $log.error(message);
      // reject(new Error(message));
      throw new Error(message);
      // return;
    }

    // if (storage) {
    //   storage.setItem('uj-page', payload);
    // }

    // if (demoOrReviewMode) {
    //   // Update local onboarding step
    //   commit('UPDATE_ONBOARDING_STEP', payload);
    //   $log.debug('Is demo/review: will invoke "DEMO_EXIT_USER_JOURNEY" to allow continuing to the user journey.');
    //   userJourney.value.exited = true;
    // }

    if (registerStepOnBackend) {
      interface PutData {
        user: {
          onboarding_step: number;
          is_last_step?: boolean;
        };
      }

      const putData: PutData = {
        user: {
          onboarding_step: payload,
        },
      };
      if (payload === lastUserJourneyStepId.value) {
        putData.user.is_last_step = true;
      }
      try {
        await $http?.put('/session/profile', putData, { signal: undefined });
        // Update state
        if (profile.value) {
          profile.value.onboarding_step = payload;
        }
        $log.debug('Onboarding step updated');
        // resolve();
      } catch (error) {
        $log.error('Onboarding step update failed:', error);
        // reject(error);
        throw error;
      } finally {
        //
      }
      // } else {
      // resolve();
    }
  }

  function setUserJourneyStepToStartOrResume() {
    const globalStore = useGlobalStore();
    let step;
    if (globalStore.isAppJgo) {
      step = currentUserJourneyPage.value || USER_JOURNEY_TALENT_MANUAL_PAGES.PHASE_2.ACCOUNT_COMPLETION;
    } else {
      step = currentUserJourneyPage.value || USER_JOURNEY_V3_PAGES.PHASE_2.P1_COMPLETE_YOUR_ACCOUNT;
    }

    updateOnboardingStep(step); // async, do not wait for it
    if (profile.value) {
      profile.value.onboarding_step = step;
    }
  }

  function exitUserJourney() {
    if (userJourneySetup.value) {
      // Exiting user journey demo or review: restore previous user role
      if (userJourneySetup.value.originalUserRoleId) {
        setLocalUserRoleFromId(userJourneySetup.value.originalUserRoleId);
      }
      $log.debug('exitUserJourney: clearing User Journey setup.');
      const { returnPage } = userJourneySetup.value; // get it before clearing
      userJourneySetup.value = null; // clear it before changing the route
      if (storage) {
        storage.removeItem('user-journey-setup');
      }
      if (returnPage) {
        router.push({ name: returnPage });
      } else {
        router.push('/');
      }
    }

    const showDebugButtons = window && window.ENV && window.ENV.SHOW_DEBUG_BUTTONS === 'true';
    if (showDebugButtons) {
      $log.debug('exitUserJourney: showDebugButtons is true, allowing to exit.');
      // Set as true (is already true if was in review or demo mode)
      userJourney.value.exited = true;
    } else {
      $log.debug('exitUserJourney: showDebugButtons is false, setting "exited" to false as the user will have to return to it later if not finished yet (can be on another role).');
      // Regular user (no debug buttons), mark exited as false to force the user
      // into the user journey if not finished yet
      userJourney.value.exited = false;
    }
    router.push('/');
  }

  function updateAvailableBackgrounds(payload: { type: BackgroundTypes; data: BackgroundSystemGradient[] | BackgroundSystemImage[] | [] | [BackgroundUserImage] }) {
    // @ts-expect-error availableBackgrounds has a specific type for each background type
    availableBackgrounds.value[payload.type] = payload.data;
  }

  function updateCurrentBackground(payload: BackgroundImageOrColorOrUserImage) {
    $log.debug('updateCurrentBackground commit. background:', payload);
    currentBackground.value = payload;

    if (payload && Object.keys(payload).length === 2 && 'image' in payload && 'thumb' in payload) {
      // User image
      availableBackgrounds.value[BackgroundTypes.USER_IMAGE] = [payload];
    }
  }

  async function changeCurrentBackground(payload: File | Blob | BackgroundImageOrColorOrUserImage) {
    $log.debug(`changeCurrentBackground action. Payload: ${payload}. Returning the promise`);
    if ('code' in payload) {
      //
      //
      //
      //
      //
      // if (payload.id >= 10) {
      //   // LOCAL ONLY, for testing grays
      //   commit('updateCurrentBackground', payload);
      //   resolve();
      //   return;
      // }
      //
      //
      //
      //

      // Changing to a gradient
      updateCurrentBackground(payload);
      const formData = new FormData();
      formData.append('settings[bg_color_id]', payload.id.toString());
      formData.append('settings[bg_image_id]', ''); // Clear previous image
      try {
        await $http?.put('/session/settings', formData, { signal: undefined });
        // resolve();
      } catch (error) {
        $log.error('Update background color failed:', error);
        // reject(error);
        throw error;
      } finally {
        //
      }
    } else if ('title' in payload) {
      // Changing to a photo
      $log.debug('Changing background to an image:', payload);
      // save selection at the backend
      updateCurrentBackground(payload);
      const formData = new FormData();
      formData.append('settings[bg_color_id]', ''); // Clear previous color
      formData.append('settings[bg_image_id]', payload.id.toString());
      try {
        await $http?.put('/session/settings', formData, { signal: undefined });
        // resolve();
      } catch (error) {
        $log.error('Update background image failed:', error);
        // reject(error);
        throw error;
      } finally {
        //
      }
    } else if ('name' in payload && 'size' in payload) {
      // Custom image
      $log.debug('Changing background to a custom image:', payload);
      // save selection at the backend
      const formData = new FormData();
      formData.append('settings[bg_color_id]', ''); // Clear previous color
      formData.append('settings[bg_image_id]', ''); // Clear previous image
      formData.append('settings[user_image]', payload, payload.name); // payload.name argument required if payload is a Blob (Edge browser), of else it will be set to "blob"
      try {
        const response: undefined | AxiosResponse<SettingsRaw> = await $http?.put('/session/settings', formData, { signal: undefined });
        $log.debug('Uploaded. Response:');
        if (response?.data && 'user_image' in response.data) {
          updateAvailableBackgrounds({ type: BackgroundTypes.USER_IMAGE, data: [response.data.user_image] });
          updateCurrentBackground(response.data.user_image);
        } else {
          $log.error('No "user_image" at the upload response');
        }
        // resolve();
      } catch (error) {
        $log.error('Update background custom image failed:', error);
        toast.showToastError('Could not upload your background, please try again later.');
        // reject(error);
        throw error;
      } finally {
        //
      }
    } else if ('image' in payload) {
      // Select existing custom image
      updateCurrentBackground(payload);
      const formData = new FormData();
      formData.append('settings[bg_color_id]', ''); // Clear previous color
      formData.append('settings[bg_image_id]', ''); // Clear previous image
      // Keep user_image
      await $http?.put('/session/settings', formData, { signal: undefined });
    }
  }

  function updateProfile(payload: ProfileRaw) {
    profile.value = {
      ...payload,
      ownProfile: true,
    };
  }

  async function changeCurrentAvatar(payload: File | Blob | { id: string } | SystemAvatar) {
    $log.debug(`changeCurrentAvatar action. Payload: ${payload}. Returning the promise`);

    if (!payload) {
      // resolve();
      return null;
    }

    if (!profile.value) {
      return null;
    }

    // Upload it now
    const formData = new FormData();
    if ('id' in payload) {
      // pre-defined avatar or previous own avatar if id is empty
      formData.append('user[avatar_id]', payload.id.toString());
      profile.value.avatar_id = payload.id.toString();
    } else if (payload instanceof File || payload instanceof Blob) {
      // new user image
      formData.append('user[avatar]', payload, ('name' in payload && payload.name) || ''); // payload.name argument required if payload is a Blob (Edge browser), of else it will be set to "blob"
      profile.value.avatar_id = null;
    }

    try {
      const response: undefined | AxiosResponse<ProfileRaw> = await $http?.put('/session/profile/avatar', formData, { signal: undefined });
      if (!response) {
        return null;
      }
      updateProfile(response.data);
      const entitiesStore = useEntitiesStore();
      entitiesStore.updateProfileAvatarOnPeopleList(profile.value);
      $log.debug('Changed avatar.');
      // resolve(currentProfile.avatar);
      return profile.value.avatar;
    } catch (error) {
      $log.error('Change avatar failed:', error);
      // reject(error);
      throw error;
    } finally {
      //
    }
  }

  function setProfileAircraftRegistrationPrefix() {
    if (!profile.value) {
      return;
    }
    profile.value.aircraftRegistrationPrefix = '';
    if (!('country_residence' in profile.value) || profile.value.country_residence === null || !('code' in profile.value.country_residence)) {
      return;
    }
    const countryCode = profile.value.country_residence.code;
    if (!(countryCode in ISO_ALPHA2_TO_AIRCRAFT_REGISTRATION_PREFIXES_MAP)) {
      return;
    }
    profile.value.aircraftRegistrationPrefix = ISO_ALPHA2_TO_AIRCRAFT_REGISTRATION_PREFIXES_MAP[countryCode as keyof typeof ISO_ALPHA2_TO_AIRCRAFT_REGISTRATION_PREFIXES_MAP].registrationPrefix;
  }

  /**
   * Fetches the user's account profile.
   * Used by fetchAccount(), which does other things (check roles, load team),
   * or used when there is an error loading the avatar image (expired S3 token).
   */
  async function fetchAccountOnly() {
    try {
      if (!$http) {
        throw new Error('$http is undefined');
      }

      const response = await $http.get<ProfileRaw>('/session/profile', { signal: undefined });

      if (!response || !response.data) {
        throw new Error('No response data from /session/profile');
      }

      // Update the profile with the retrieved data
      updateProfile(response.data);
    } catch (error) {
      const message = 'There was an error fetching your account. Please try again later.';
      $log.error(`Profile fetching failed; showing "${message}". Error:`, error);

      const authStore = useAuthStore();
      await authStore.signOut(); // will reset the store and redirect to SignIn page

      // Rethrow the error if needed
      throw error;
    } finally {
      //
    }
  }

  /**
   * Handles the user role by checking and setting it appropriately.
   */
  async function handleUserRole() {
    let currentRole = userRole.value; // Default will be NONE (-1)

    if (!currentRole || currentRole.id === ALL_ROLES.NONE.id) {
      // Attempt to retrieve the previous role from storage
      const roleIdFromStorage = storage?.getItem('user-role');
      if (roleIdFromStorage) {
        setLocalUserRoleFromId(Number.parseInt(roleIdFromStorage, 10));
        currentRole = userRole.value;
      }
    }

    // Verify if the current role exists in the updated profile
    const hasCurrentRole = profile.value?.roles.some((role) => role.id === currentRole.id);

    if (hasCurrentRole) {
      $log.debug(`Fetched profile and previously selected role (${currentRole.id}) exists. Keeping it selected.`);
    } else {
      checkAndFixUserRole();
    }
  }

  /**
   * Loads additional data based on the user's roles.
   */
  async function loadRoleSpecificData() {
    const hasImpRole = userHasImpTeamLeaderRole.value || userHasImpCoTeamLeaderRole.value || userHasImpTeamMemberRole.value;

    if (hasImpRole) {
      const impulsesStore = useImpulsesStore();
      await Promise.all([
        impulsesStore.getNumberOfActiveImpulsesForEachRole(),
        impulsesStore.getPersonalDevelopmentAreaDataForEachRole(),
      ]);
    }

    const isInImpRole = userRoleIsImpTeamLeader.value || userRoleIsImpCoTeamLeader.value || userRoleIsImpTeamMember.value;
    if (profile.value?.client && isInImpRole) {
      const entitiesStore = useEntitiesStore();
      await entitiesStore.loadPeopleAndGroups();
    }
  }

  async function fetchAccount() {
    try {
      // Fetch the user's account profile
      await fetchAccountOnly();

      // Set the aircraft registration prefix based on the updated profile
      setProfileAircraftRegistrationPrefix();

      // Manage user roles
      await handleUserRole();

      // Load additional data if the user has specific roles
      await loadRoleSpecificData();
    } catch (error) {
      $log.error(`fetchAccount error:`, error);
      throw error;
    } finally {
      //
    }
  }

  /**
   * Saves the user's timezone setting.
   * @param timezone - The timezone to save.
   * @throws Will throw an error if the HTTP request fails.
   */
  async function saveTimeZone(timezone: string): Promise<void> {
    try {
      // Ensure $http is defined
      if (!$http) {
        throw new Error('$http is undefined');
      }

      const putData = { settings: { timezone } };
      const response = await $http.put<SettingsRaw>('/session/settings', putData, { signal: undefined });

      // Update the settings if the response contains the timezone
      if (response.data.timezone && settings.value) {
        settings.value.timezone = response.data.timezone;
      } else {
        throw new Error('Failed to update timezone: response data is missing');
      }
    } catch (error) {
      $log.error('Error saving timezone:', error);
      throw new Error('Failed to save timezone'); // for the App.vue, to show a toast message
    } finally {
      //
    }
  }

  async function checkTimezoneMismatch() {
    if (typeof Intl === 'undefined') {
      // Intl not supported
      timeZoneMismatch.value = null;
      return;
    }

    const guessed = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (!guessed) {
      $log.warn('Unable to determine browser timezone.');
      timeZoneMismatch.value = null;
      return;
    }

    const userTimezone = settings.value?.timezone;
    $log.debug(`Guessed time zone: ${guessed}; settings time zone: ${userTimezone}`);

    if (!userTimezone) {
      $log.info(`User has no time zone set. Setting it to ${guessed}.`);
      await saveTimeZone(guessed);
      timeZoneMismatch.value = null;
      return;
    }

    if (guessed !== userTimezone) {
      $log.debug(`Guessed time zone (${guessed}) is different from settings time zone (${userTimezone})`);
      timeZoneMismatch.value = {
        browser: guessed,
        settings: userTimezone,
      };
    } else {
      timeZoneMismatch.value = null;
    }
  }

  async function fetchSettings() {
    try {
      interface SettingsResponse {
        data: SettingsRaw;
      }

      const response: undefined | SettingsResponse = await $http?.get('/session/settings', { signal: undefined });
      if (!response) {
        return null;
      }
      $log.debug('Action fetchSettings got settings response');

      const settingsData = response.data;

      const processNewSettings = () => {
        $log.debug(settingsData);
        // bg_color: null
        // bg_image: null
        // id: 123
        // is_public_talent_manual: false
        // replace_crew_member_id: null
        // replace_pilot_id: null
        // selected_profile_id: 1
        // system_language_id: null
        // timezone: null
        // user_image: {image: null, thumb: null}
        settings.value = settingsData; // Save the settings

        if (settingsData.system_language_id) {
          const translationStore = useTranslationStore();
          translationStore.changeLangFromID(settingsData.system_language_id);
        }

        if (settingsData.timezone && settings.value) {
          settings.value.timezone = settingsData.timezone;
        }

        // Set the current background
        const bgColor = settingsData.bg_color;
        const bgImage = settingsData.bg_image;
        const userImage = settingsData.user_image;
        if (bgColor) {
          // Background color exists on user settings
          updateCurrentBackground(bgColor);
        } else if (bgImage) {
          // Background image exists on user settings
          if (currentBackground.value !== bgImage) {
            // Change only if different, to avoid a flicker on screen (same image loading again)
            updateCurrentBackground(bgImage);
          }
        } else if (userImage && userImage.image) {
          // User image exists on user settings
          if (currentBackground.value !== userImage) {
            // Change only if different, to avoid a flicker on screen (same image loading again)
            updateCurrentBackground(userImage);
          }
        } else {
          // No background? Keep the default.
        }

        if (userImage) {
          // If there is a user image (even if not selected), add it to the available user images, so we can show it at the backgrounds list
          updateAvailableBackgrounds({ type: BackgroundTypes.USER_IMAGE, data: [userImage] });
        }
      };

      processNewSettings();
      await checkTimezoneMismatch();
      return settingsData; // or else the result of the promise will be undefined
    } catch (error: unknown | AxiosError) {
      if (error instanceof AxiosError) {
        // Access to config, request, and response
        const NOT_FOUND = 404 as const;
        if (error.response?.status === NOT_FOUND && error.response?.headers['x-request-id']) {
          $log.debug('The user doesn’t have settings yet.');
        }
      } else {
        // Just a stock error
      }
    } finally {
      //
    }
    return null; // promise result if not returned inside try{}
  }

  async function fetchAccountAndSettings() {
    const authStore = useAuthStore();

    if (!authStore.isAuthenticated) {
      throw new Error('Trying to fetch the account but user is not authenticated.');
    }

    try {
      $log.debug('Fetching account and settings...');
      await Promise.all([fetchAccount(), fetchSettings()]);
      $log.debug('Successfully fetched account and settings.');
    } catch (error) {
      $log.error('Failed to fetch account and settings:', error);
      throw error;
    }
  }

  async function fetchBackgrounds() {
    // Needs authentication
    // Fetch in parallel (do not use await)
    interface ColorsResponse {
      data: {
        data: BackgroundSystemGradient[];
      };
    }

    const colorsPromise = $http?.get('/config/colors/bg', { signal: undefined })
      .then((response: ColorsResponse) => {
        const { data } = response.data;
        // if (process.env.VITE_APP_NAME === 'jgo') {
        //   data = [
        //     { id: 50, code: '#f2babd' },
        //     { id: 51, code: '#f4f1be' },
        //     { id: 52, code: '#c8efec' },
        //     { id: 53, code: '#b1cce1' },
        //   ];
        // }
        updateAvailableBackgrounds({
          type: BackgroundTypes.SYSTEM_GRADIENT,
          data,
          // data: [
          //   // { id: 16, code: '#b9e3f5' },
          //   ...data.filter(color => color.id === 5),
          //   ...data.filter(color => color.id === 1),
          //   // { id: 17, code: '#f7becb' },
          //   ...data.filter(color => color.id === 6),
          //   ...data.filter(color => color.id === 2),
          //   ...data.filter(color => color.id === 4),
          //   { id: 17, code: '#9da7b3' },
          // ],
        });
        // Replace grey by "DAK" orange for the client demo
        // const bgGradients = availableBackgrounds.value[BackgroundTypes.SYSTEM_GRADIENT];
        // const greyItem = bgGradients.length - 2;
        // bgGradients[greyItem].name = 'DAK';
        // bgGradients[greyItem].code = '#EC7D27';
      })
      .catch((error: unknown) => {
        $log.error('Failed fetching the system background gradients.', error);
      });

    interface ImagesResponse {
      data: BackgroundSystemImage[];
    }

    const imagesPromise = $http?.get('/config/images/bg', { signal: undefined })
      .then((response: ImagesResponse) => {
        updateAvailableBackgrounds({ type: BackgroundTypes.SYSTEM_IMAGE, data: response.data });
      })
      .catch((error: unknown) => {
        $log.error('Failed fetching the system background images.', error);
      });

    await Promise.all([colorsPromise, imagesPromise]);
  }

  async function fetchAvatars() {
    if (availableAvatars.value.length) {
      // Use existing ones
      // resolve();
      return;
    }
    try {
      interface SystemAvatarsImagesResponse {
        data: {
          data: SystemAvatar[];
        };
      }

      const response: undefined | SystemAvatarsImagesResponse = await $http?.get('/system/avatars/images', { signal: undefined });
      if (response?.data.data) {
        availableAvatars.value = response.data.data;
        // resolve();
      } else {
        const message = 'Avatars has no "data".';
        $log.error(message);
        // reject(new Error(message));
      }
    } catch (error) {
      $log.error('Failed fetching the system avatars.', error);
      // reject(error);
    } finally {
      //
    }
  }

  function selectCoPilotPilot(payload: CoTeamLeader) {
    selectedCoPilotPilot.value = payload;
    const entitiesStore = useEntitiesStore();
    entitiesStore.loadPeopleAndGroups();
  }

  const fetchCoPilotPilots = useThrottleFn(async () => {
    const nowMillis = (new Date()).getTime();
    const TEN_SECONDS = 1000 * 10;
    if (lastCoPilotPilotsFetchTime && nowMillis - lastCoPilotPilotsFetchTime < TEN_SECONDS) {
      $log.debug('fetchCoPilotPilots(): fetched less than 10 seconds ago');
      return;
    }
    lastCoPilotPilotsFetchTime = nowMillis;
    // fetch data only if last fetch was more than 10 seconds ago.
    try {
      interface CockpitPilotsResponse {
        data: {
          pilots: CoTeamLeader[];
        };
      }

      const response: undefined | CockpitPilotsResponse = await $http?.get('/session/cockpit/pilots', { signal: undefined });
      if (response?.data && response.data.pilots) {
        console.log(response.data.pilots);
        coPilotPilots.value = response.data.pilots;
        if (!selectedCoPilotPilot.value && response.data.pilots.length) {
          // Not selected? Select the first one.
          selectCoPilotPilot(response.data.pilots[0]);
        }
      } else {
        $log.debug('fetchCoPilotPilots(): no response.data or no "pilots" in response.data', response);
      }
    } catch (error) {
      $log.error('fetchCoPilotPilots(): failed fetching the Co-Team Leader cockpit Pilots.', error);
    } finally {
      //
    }
  }, 10000, false, true);

  async function changeRoleIDServerSide(payload: number) {
    $log.debug('changeRoleIDServerSide: starting');
    const formData = new FormData();
    formData.append('settings[selected_profile_id]', payload.toString());
    try {
      const response: undefined | AxiosResponse<SettingsRaw> = await $http?.put('/session/settings', formData, { signal: undefined });
      $log.debug('changeRoleIDServerSide: PUT to settings OK');
      if (response?.data && response.data.selected_profile_id) {
        const roleId = response.data.selected_profile_id;

        // Must go through the User Journey?
        const roleHasUserJourney = [ALL_ROLES.IMP_TEAM_MEMBER.id as number, ALL_ROLES.IMP_TEAM_LEADER.id as number, ALL_ROLES.IMP_CO_TEAM_LEADER.id as number].includes(roleId);
        const demoOrReviewMode = userJourneySetupIsDemoMode.value || userJourneySetupIsDemoMode.value;
        const userJourneyFinished = currentUserJourneyPage.value && userFinishedUserJourney.value;
        if (roleHasUserJourney && !demoOrReviewMode && !userJourneyFinished) {
          // Start/resume User Journey
          $log.debug(`Changing to role ID ${roleId}, not on demo/review mode, and did not finish User Journey (${currentUserJourneyPage.value}); starting/resuming it.`);
          setUserJourneyStepToStartOrResume();
        }

        // Update state
        $log.debug(`User role updated: ${roleId}`);
        const userRoleTmp = Object.values(ALL_ROLES).find((r) => r.id === roleId);
        if (userRoleTmp) {
          setLocalUserRole(userRoleTmp);
          if (userRoleIsImpTeamLeader.value || userRoleIsImpTeamMember.value) {
            // for Co-Pilots this will be called at selectCoPilotPilot
            $log.debug('changeRoleIDServerSide: will dispatch loadPeopleAndGroups with ignoreCurrentPromise = true');
            const entitiesStore = useEntitiesStore();
            await entitiesStore.loadPeopleAndGroups({ resetSelectedPerson: true, ignoreCurrentPromise: true });
          }
          if (userRoleIsImpCoTeamLeader.value) {
            $log.debug('changeRoleIDServerSide: will dispatch fetchCoPilotPilots');
            fetchCoPilotPilots();
          }
          // resolve();
        } else {
          const message = `Unknown role ID: ${payload}`;
          $log.error(message);
          // reject(new Error(message));
        }
      } else {
        const message = 'Tried to update user role, but settings response has no user role.';
        $log.error(message);
        // reject(new Error(message));
      }
    } catch (error) {
      const message = `User role update failed: ${error}`;
      $log.warn(message);
      // reject(new Error(message));
    } finally {
      //
    }
  }

  setTimeout(() => {
    // this setTimeout is here to let the router finish the first navigation before we
    // navigate to the user journey route after reading from the local storage (if needed)
    // and to give time for the auth store to know if we are signed in
    readStuffFromLocalStorage(); // demo/review modes, uj page
  }, 2000);

  return {
    //
    // State
    //
    profile,
    settings,
    allRoles,
    userRole,
    availableBackgrounds,
    currentBackground,
    availableAvatars,
    timeZoneMismatch,
    userJourney,
    userJourneySetup,
    invitationInfo,
    coPilotPilots,
    selectedCoPilotPilot,
    //
    // Getters
    //
    userProfile,
    userId,
    userHasTalentManual,
    userHasActiveCockpit,
    roleHasAction,
    userCanAccessInvitations,
    userCanAccessSixPack,
    userCanAccessGroupDynamics,
    userCanAccessScenarioManager,
    userCanAccessTalentManual,
    userRoleHasAction,
    userRoles,
    userRolesIds,
    userRoleCode,
    userRoleId,
    userRoleIsNone,
    userRoleIsAdministrator,
    userHasAdministratorRole,
    userRoleIsTalentManual,
    userRoleIsImpLAndDManager,
    userHasImpLAndDManagerRole,
    userRoleIsImpTeamLeader,
    userHasImpTeamLeaderRole,
    userRoleIsImpTeamMember,
    userHasImpTeamMemberRole,
    userRoleIsImpConsultant,
    userHasImpConsultantRole,
    userRoleIsImpCoTeamLeader,
    userHasImpCoTeamLeaderRole,
    //
    userRoleIsJgoPartner,
    userHasJgoPartnerRole,
    userRoleIsJgoParticipant,
    //
    userRoleIsTlyHr,
    userHasTlyHrRole,
    userRoleIsTlyTeamLeader,
    userHasTlyTeamLeaderRole,
    userRoleIsTlyCandidate,
    userHasTlyCandidateRole,
    userRoleIsTlyHrAdmin,
    userHasTlyHrAdminRole,
    userRoleIsTlyTeamMember,
    userHasTlyTeamMemberRole,
    //
    userRoleIsZlyTeamLeader,
    userHasZlyTeamLeaderRole,
    userRoleIsZlyTeamMember,
    userHasZlyTeamMemberRole,
    //
    userJourneySetupIsDemoMode,
    userJourneySetupIsReviewMode,
    currentUserJourneyPage,
    lastUserJourneyStepId,
    userFinishedUserJourney,
    // userFullName,
    // userFirstName,
    userAvatarUrl,
    userPersonalAvatarUrl,
    availableBackgroundColors,
    availableBackgroundImages,
    availableBackgroundsCustom,
    selectedAvatarId,
    userTimezone,
    exitedUserJourney,
    isTemporaryAccount,
    isTalentManualUser,
    isAllowedToDownloadTalentManual,
    isAllowedToDownloadOthersTalentManual,
    timeZoneMismatchLastShown,
    //
    // Actions
    //
    resetState,
    injectRouter,
    // readStuffFromLocalStorage,
    checkAndFixUserRole,
    // saveUserJourneySetup,
    updateOnboardingStep,
    // setUserJourneyStepToStartOrResume,
    exitUserJourney,
    enterUserJourneyTalentManual,
    enterUserJourneyV3,
    setInvitationInfo,
    // updateAvailableBackgrounds,
    // updateCurrentBackground,
    changeCurrentBackground,
    changeCurrentAvatar,
    updateProfile,
    fetchAccountAndSettings,
    // setProfileAircraftRegistrationPrefix,
    fetchAccountOnly,
    fetchAccount,
    // checkTimezoneMismatch,
    fetchSettings,
    fetchBackgrounds,
    fetchAvatars,
    setLocalUserRole,
    setLocalUserRoleFromId,
    changeRoleIDServerSide,
    fetchCoPilotPilots,
    selectCoPilotPilot,
    saveTimeZone,
    setTimeZoneMismatchLastShown,
  };
});
