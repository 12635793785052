// Pinia Store
import { ref } from 'vue';
// import type { App } from 'vue';
import { defineStore } from 'pinia';

console.log('Pinia Questionnaire store is being created.'); // no access to Vue.prototype.$log here yet

// let app: App;

const useQuestionnaireStore = defineStore('questionnaire', () => {
  const answeringQuestionnaireFor = ref<null | {
    firstName: string;
    lastName: string;
    target_user: {
      id: number;
    };
  }>(null);

  function resetState() {
    answeringQuestionnaireFor.value = null;
  }

  function setAnsweringQuestionnaireFor(payload: {
    firstName: string;
    lastName: string;
    target_user: {
      id: number;
    };
  }) {
    answeringQuestionnaireFor.value = payload;
  }

  function unsetAnsweringQuestionnaireFor() {
    answeringQuestionnaireFor.value = null;
  }

  return {
    //
    // State
    //
    answeringQuestionnaireFor,
    //
    // Getters
    //

    //

    //
    // Actions
    //
    resetState,
    setAnsweringQuestionnaireFor,
    unsetAnsweringQuestionnaireFor,
  };
});

export { useQuestionnaireStore };
